<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">コスト削減企業一覧</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card-box">
          <list-table :viewParams="viewParams" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/ListTable.vue'

export default {
  name: 'CorpList',
  components: {
    ListTable,
  },
  data() {
    return {
      viewParams: {
        name: 'corp5',
        apiUrl: '/v1/corps5/',
        articleView: 'CorpArticle1',
        articleParamId: 'ID',
        dataType:5,
      }
    }
  }
}
</script>